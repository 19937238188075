<template>
    <div class="companies">
        <list/>
        <button
            v-if="current_user.is_admin"
            class="sidebar__btn sidebar__btn--add v-btn"
            @click="addStaff"
        >
            + Добавить сотрудника
        </button>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "Companies",
        components: {
            List : () => import('./List')
        },

        computed: {
            ...mapGetters('default_data', ['current_user']),
        },

        methods: {
            addStaff() {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const ChangeStaffModal = () => import(`@/components/staff/detail/ChangeStaffModal`);

                    this.$modal.show(ChangeStaffModal, {}, {
                        name: 'change-staff-modal',
                        styles:{ overflow: "inherit", },
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        pivotY: 0,
                        clickToClose: false
                    })
                }
            },
        },
    }
</script>

<style scoped>
    .companies {
        padding: 10px;
    }
</style>
